<template>
    <nav id="nav" :class="{
        'not-empty': this.$store.state.alertString.trim() !== '',
        'empty': this.$store.state.alertString.trim() === ''
      }">
    <div class="nav-inner-container">
      <a class="col nav-item" href="https://app.orderopia.com/pats-fish-bar/order" >Order Now</a>
    
      
      <div :class="{
        'not-empty': this.$store.state.alertString.trim() !== '',
        'empty': this.$store.state.alertString.trim() === ''
      }"  class="nav-logo-container col-auto">
      <router-link to="/"><div alt="logo" class="nav-logo"></div></router-link>
      </div>
      <router-link class="col nav-item" to="/contact">Contact</router-link>
      <button @click="toggled = !toggled" class="hamburger-menu-btn"><img alt="hamburger" src="../assets/img/hamburger-btn.webp"></button>
    </div>
  </nav>

  <div :class="{ toggled: toggled}" id="flyout-nav">
  <div class="d-flex flex-column" style="height:100%">

    <div class="py-4 me-4 mt-3 mb-5 justify-content-between d-flex align-items-center">
      <div></div>
      <img id="flyout-logo" src="../assets/img/logo.webp"/>
      <button @click="toggled = !toggled" class="btn-icon">
        <img alt="close" src="../assets/img/close-dark.webp">
      </button>
    </div>


    <div >
    <a href="https://app.orderopia.com/pats-fish-bar/order">Order Now</a>

    </div>

   <div id="flyout-nav-bottom">
    <router-link @click="this.toggled = false" to="/contact">Contact</router-link>
   </div>
  </div>
  <div>

  </div>



  </div>


</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    
    return {
    //   images: [
    //   require("./assets/img/logo.webp")
    // ],
      toggled: false
    }
  },
  mounted() {
    // eslint-disable-next-line
    initOrderopia();
  }

}

import $ from 'jquery';


$(function () {
  $(document).scroll(function () {
    var $nav = $("#nav");
    $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
  });
});

//var toggled;

// function toggleMenu() {
//   toggled = !toggled;
//   var $flyout = $("#flyout-nav");

//   if(toggled) {
//     $flyout.addClass('toggled');
//   }
//   else {
//     $flyout.removeClass('toggled');
//   }
// }


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.social-media-btn {
margin-right:15px;
margin-top:10px;
}
.social-media-btn img {
  height:30px;
  width:30px;
}

a {
  text-align: center;
}

nav {
  margin-top:25px;
  position:fixed;
  top:0;
  z-index: 999;
  width:100%;
  display: flex;
  left:0;
  right:0;
  align-items: center;
  height:120px;
  transition:all 200ms linear;
}

nav.not-empty {
  margin-top:35px;

}

nav .nav-inner-container {
  margin:0 auto;
  max-width:800px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width:100%;
  height:100%;
}

nav a.router-link-exact-active {
  color: var(--primary);
}

nav.scrolled {
  background-color: var(--background) !important;
  height:80px;
  transition: all 200ms linear;
  margin-top:0;
}

.nav-logo {
  height:170px;
  width:170px;
  margin-top:10px;
  transition:all 200ms linear;
  background-image: url('../assets/img/logo.webp');
  background-size: cover;
  background-position: center;
}


nav.scrolled .nav-logo {
  width:120px;
  height:120px;
  transition:all 200ms linear;
  margin-top:65px;
  background-image: url('../assets/img/logo-dark.webp');

}

nav a {
  text-decoration: none;
  color:white;
}



.hamburger-menu-btn {
  background-color: transparent;
  border:none;
  outline: none;
  display: none;
  z-index: 99;
  cursor:pointer;
  height:100%;
  width:80px;
  margin-left:10px;
  margin-top: 10px;
}

.hamburger-menu-btn img {
  height:55px;
}

#flyout-nav {
  width:50%;
  z-index:9999;
  position:fixed;
  bottom:0;
  left:-100%;
  top:0;
  background-color:var(--background);
  display:flex;
  flex-direction: column;
  transition:left 0.3s ease-in-out;
  max-width:100%;
}

#flyout-nav * {
  display: block;
  text-align: start;
}

#flyout-nav.toggled {
  left:0;
  transition:left 0.2s ease-in-out;
}

#flyout-nav a {
  height:50px;
  font-size:20px;
  text-align: center;
  line-height: 50px;
  margin-top: 1.5rem!important;
  margin-bottom: 1.5rem!important;
}

#flyout-logo {
  width:150px;
  height: 150px;
  position: absolute;
  left:50%;
  transform: translateX(-50%);
}

.nav-item {
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

nav.scrolled .nav-item {
  color:black;
}



#flyout-nav-bottom {
  background-color: var(--background-dark);
  width:100%;
  flex: 1 1 auto;
}

.nav-logo-container.not-empty {
  top:60px;
  transition:all 200ms linear;
}

.nav-logo-container.not-empty .nav-logo {
  height:100px;
}

nav.scrolled .nav-logo-container.not-empty {
  top:0;
}

@media (max-width:800px) {

#flyout-nav {
  width:100%;
}

nav {
max-width:100%;

}
nav .nav-item {
  display: none;
}

.nav-logo {
  margin-top: 15px;
  height:180px;
  width:180px;
}

.nav-logo-container {
  position:fixed;
  left:50px;
  right:50px;
  top:0;
  display: flex;
  align-items: center;
  justify-content: center;
}



nav.scrolled .nav-logo {
  height:120px;
  width:120px;
  transition:all 200ms linear;
  margin-top:16px;
}

.hamburger-menu-btn {
  display: block;
}




}

</style>
